import React, { useEffect, useRef, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import { TransitionState } from 'gatsby-plugin-transition-link';

import gsap from 'gsap';
import { RoughEase } from 'gsap/EasePack';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import clsx from 'clsx';
import Layout from '../components/layout';
import Meta from '../components/meta';
import { KenyaMapIcon } from '../components/svgs';
import { Button, Header } from '../components';
import { isBrowser } from '../helpers/isBrowser';
import lottie from 'lottie-web';
import * as animationData from '../lottie/data.json';

const lottieSegments = {
	'LowHigh': {
		segments: [0, 60],
		direction: 1,
	},
	'LowMedium': {
		segments: [0, 30],
		direction: 1,
	},
	'MediumLow': {
		segments: [30, 0],
		direction: -1,
	},
	'MediumHigh': {
		segments: [30, 60],
		direction: 1,
	},
	'HighLow': {
		segments: [60, 0],
		direction: -1,
	},
	'HighMedium': {
		segments: [60, 30],
		direction: -1,
	},
};

const Resolution = ({ handleClick, picked, name }) => {
	return (
		<div className={clsx('resolution_picker', {'resolution_picker__selected' : picked === name} )}>
			<span
				onClick={() => handleClick(name)}
				className='font-bold cursor-pointer font-playFair text-15'
			>
				{name}
			</span>
			<div className={clsx('selected_underline', {'selected' : picked === name} )}></div>
		</div>
	);
};

const ProgressiveImageLoader = () => {
	const hiResRef = useRef(null);

	const handleLoad = () => {
		if(hiResRef !== null) {
			const tl = gsap.timeline();

			tl
				.to(hiResRef.current, {
					opacity: 1,
					scale: 1,
					duration: 1,
				})
				.fromTo(hiResRef.current, {
					x: 0,
					y: 0,
				}, {
					delay: 9,
					x: -15,
					y: 10,
					duration: 4,
					ease: RoughEase.ease.config({
						strength: 12,
						points: 180,
						template: 'Power0.easeInOut',
						randomize: true,
						taper: 'both',
						clamp: false,
					}),
				})
				.to(hiResRef.current, {
					x: 0,
					y: 0,
					ease: RoughEase.ease.config({
						strength: 3,
						points: 20,
						template: 'Power0.easeInOut',
						randomize: true,
						taper: 'both',
						clamp: false,
					}),
				});
		}
	};

	return <div className="fixed inset-0 w-screen h-screen overflow-hidden opacity-100 imgtest">
		<img
			className="imgtest__preview imgtest__image"
			src="https://a.storyblok.com/f/126918/256x144/34a6e48aaf/indexbackground-min10.jpg"
		/>
		<img
			onLoad={() => { handleLoad(); }}
			ref={hiResRef}
			src="https://a.storyblok.com/f/126918/2560x1440/e1ec3abce9/indexbackground-min.jpg"
			className="absolute inset-0 opacity-0 imgtest__image"
		/>
	</div>;
};

const Page = () => {
	const exitEl = useRef();
	const leftCloud = useRef();
	const rightCloud = useRef();
	const airplane = useRef();
	const lottieRef = useRef();
	const cloudTimeline = gsap.timeline({ paused: true });

	const [resolution, setResolution] = useState('High');
	const [animation, setAnimation] = useState(null);

	const handlePick = name => {

		if (resolution !== name) {
			const direction = lottieSegments[`${resolution}${name}`].direction;
			const segments = lottieSegments[`${resolution}${name}`].segments;
			animation.setDirection(direction);
			animation.playSegments(segments, true);
			setResolution(name);
		}
	};

	useEffect(() => {
		if(isBrowser()) {
			gsap.registerPlugin(MotionPathPlugin);
			if(leftCloud.current) {
				gsap.set(leftCloud.current, {
					left: 0,
					y: '-30vh',
					xPercent: -100,
					opacity: 1,
				});
				cloudTimeline.to(leftCloud.current, {
					xPercent: 100,
					x: '100vw',
					duration: 120,
					ease: 'none',
					repeat: -1,
				}).play();
			}
			if(rightCloud.current) {
				gsap.set(rightCloud.current, {
					right: 0,
					y: '0vh',
					xPercent: 90,
					opacity: 1,
				});
				cloudTimeline.to(rightCloud.current, {
					xPercent: -100,
					x: '-100vw',
					duration: 120,
					ease: 'none',
					repeat: -1,
				}, 0).play();
			}
			if(airplane.current) {
				gsap.set(airplane.current, {
					opacity: 1,
					x: '100vw',
					y: '100vh',
					rotate: -9,
				});
				gsap.to(airplane.current, {
					x: '-100vw',
					y: '-100vh',
					duration: 5,
					ease: 'none',
					delay: 10,
				});
			}
		}
		
	}, []);

	const handleNavigate = () => {
		const tl = gsap.timeline();
		tl
			.to(
				'.fader', {
					opacity: 0,
					duration: .7,
				},
			)
			.to('.imgtest', {
				scale: 1.2,
				duration: 4,
			}, '<')
			.to('.imgtest', {
				opacity: 0,
				duration: 2,
			}, '-.3');
		navigate('/explore', { state: { resolution } });
	};

	useEffect(()=> {
		if(lottieRef.current){
			const animation = lottie.loadAnimation({
				container: lottieRef.current,
				renderer: 'svg',
				loop: false,
				autoplay: false,
				animationData: animationData,
				initialSegment: [60, 30],
			});
			setAnimation(animation);
		}
	}, []);

	return (
		<TransitionState>
			{() => {
				return (
					<Layout>
						<Meta />
						<Header hasBack={false} color="#ffffff" />
						<ProgressiveImageLoader />
						<div className="relative flex flex-col items-center justify-center w-screen h-screen overflow-hidden fader awards landing-page">
							<img ref={airplane} className="absolute opacity-0 pointer-events-none" src="/airplane.png" />
							<img ref={leftCloud} className="absolute max-w-lg opacity-0 pointer-events-none" src="/cloud_1.png" />
							<img ref={rightCloud} className="absolute max-w-lg opacity-0 pointer-events-none" src="/cloud_1.png" />
							<div ref={exitEl} className="absolute top-0 left-0 w-full h-full origin-left transform scale-x-0 pointer-events-none bg-primary"></div>
							<KenyaMapIcon className="kenyamap__icon" fill="#fff"/>
							<p className="mb-16 text-white-100 font-playFair text-17 mt-36">Please select your map exprience.</p>
							<div className="flex flex-col justify-items-center w-300">
								<div ref={lottieRef}></div>
								<div className="flex justify-between">
									<Resolution handleClick={handlePick} name="Low" picked={resolution}/>
									<Resolution handleClick={handlePick} name="Medium" picked={resolution}/>
									<Resolution handleClick={handlePick} name="High" picked={resolution}/>
								</div>
							</div>
							<div className="z-10 flex my-64">
								<Button onClick={handleNavigate} variant="linkGhostButton" className="mx-auto">Explore</Button>
							</div>
						</div>
					</Layout>
				);
			}}
		</TransitionState>
	);
};

export default Page;

export const query = graphql`
	query awardsQuery {
		allStoryblokEntry(filter: {name: {eq: "index"}}) {
			nodes {
				content
			}
		}
	}
`;
